import { validation } from '@upa/design-system';
import { useI18n } from 'vue-i18n';

export default function useFormGroupRegistration() {
  const { t } = useI18n();

  const getRegistration = (salutations: any, countries: any, address: any) => {
    return [
      {
        positionFormGroup: 0,
        title: t('checkout.addressForm.professional.header'),
        fieldList: [
          {
            type: 'select',
            name: 'salutation',
            label: t('checkout.addressForm.professional.fields.salution.label'),
            required: true,
            disabled: false,
            width: 6,
            position: 1,
            optionList: salutations || [],
            entityName: 'salutation',
            value: {
              id: address?.salutationId || '',
              name: address?.salutation || '',
            },
            validationList: [
              {
                message: t(
                  'checkout.addressForm.professional.fields.salution.required',
                ),
                rule: validation.required,
              },
            ],
          },
          {
            type: 'checkbox',
            name: 'checkbox_company',
            label: t(
              'checkout.addressForm.professional.fields.company.checkboxLabel',
            ),
            required: false,
            disabled: false,
            width: 6,
            value: address?.company ? true : false,
            position: 1,
          },
          {
            type: 'company',
            name: 'company_field',
            label: t('checkout.addressForm.professional.fields.company.label'),
            required: false,
            disabled: false,
            width: 12,
            value: address?.company || '',
            position: 1,
          },
          {
            type: 'text',
            name: 'firstName',
            label: t(
              'checkout.addressForm.professional.fields.firstName.label',
            ),
            required: true,
            disabled: false,
            width: 6,
            value: address?.firstName || '',
            position: 1,
            validationList: [
              {
                message: t(
                  'checkout.addressForm.professional.fields.firstName.required',
                ),
                rule: validation.required,
              },
            ],
          },
          {
            type: 'text',
            name: 'lastName',
            label: t('checkout.addressForm.professional.fields.lastName.label'),
            required: true,
            disabled: false,
            width: 6,
            value: address?.lastName || '',
            position: 1,
            validationList: [
              {
                message: t(
                  'checkout.addressForm.professional.fields.lastName.required',
                ),
                rule: validation.required,
              },
            ],
          },

          {
            type: 'text',
            name: 'street',
            label: t('checkout.addressForm.professional.fields.street.label'),
            required: true,
            disabled: false,
            width: 12,
            value: address?.street || '',
            position: 1,
            validationList: [
              {
                message: t(
                  'checkout.addressForm.professional.fields.street.required',
                ),
                rule: validation.required,
              },
            ],
          },
          {
            type: 'text',
            name: 'additional_address',
            label: t(
              'checkout.addressForm.professional.fields.additionalAddress.label',
            ),
            required: true,
            disabled: false,
            width: 12,
            value: address?.additionalAddressLine1 || '',
            position: 1,
          },
          {
            type: 'text',
            name: 'zipcode',
            label: t('checkout.addressForm.professional.fields.zipcode.label'),
            required: true,
            disabled: false,
            width: 6,
            value: address?.zipcode || '',
            position: 1,
            validationList: [
              {
                message: t(
                  'checkout.addressForm.professional.fields.zipcode.required',
                ),
                rule: validation.required,
              },
            ],
          },
          {
            type: 'text',
            name: 'city',
            label: t('checkout.addressForm.professional.fields.city.label'),
            required: true,
            disabled: false,
            width: 6,
            value: address?.city || '',
            position: 1,
            validationList: [
              {
                message: t(
                  'checkout.addressForm.professional.fields.city.required',
                ),
                rule: validation.required,
              },
            ],
          },
          {
            type: 'select',
            name: 'country',
            label: t('checkout.addressForm.professional.fields.country.label'),
            required: true,
            disabled: false,
            width: 6,
            value: {
              id: address?.countryId || '',
              name: address?.country || '',
            },
            optionList: countries || [],
            entityName: 'country',
            position: 1,
            validationList: [
              {
                message: t(
                  'checkout.addressForm.professional.fields.country.required',
                ),
                rule: validation.required,
              },
            ],
          },
          {
            //TODO: config einbauen sales channel
            type: 'text',
            name: 'phonenumber',
            label: t('checkout.addressForm.professional.fields.tel.label'),
            required: false,
            disabled: false,
            width: 12,
            value: address?.phonenumber || '',
            position: 1,
          },
          {
            type: 'email',
            name: 'email',
            label: t('checkout.addressForm.professional.fields.email.label'),
            required: false,
            disabled: false,
            width: 12,
            value: address?.email || '',
            position: 1,
            validationList: [
              {
                message: t(
                  'checkout.addressForm.professional.fields.email.required',
                ),
                rule: validation.emailFormat,
              },
            ],
          },
        ],
      },
    ];
  };

  return { getRegistration };
}
